<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <div class="module_item">
        <div class="form_box">
          <el-form-item class="form_item" label="任务名称：" prop="taskName">
            <el-input v-model="formData.taskName" :disabled=disabled ></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="任务说明：" prop="taskDesc">
            <el-input v-model="formData.taskDesc" :disabled=disabled ></el-input>
            <div class="form_item_explain">用于H5分享案例说明</div>
          </el-form-item>
          <el-form-item label="分享小图：" prop="logoLink">
            <file-upload
              v-model="formData.shareImage"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="shareImageList"
              :maxNum="1"
              width="72px"
              height="72px"
              :disabled=disabled
              @uploadSuccess="uploadSuccess('')"
            ></file-upload>
          </el-form-item>
          <el-form-item label="专属码图：" prop="logoLink">
            <file-upload
              v-model="formData.invitationImage"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="invitationImageList"
              :maxNum="1"
              width="72px"
              height="72px"
              :disabled=disabled
              @uploadSuccess="uploadSuccess('')"
            ></file-upload>
          </el-form-item>
          <el-form-item class="form_item" label="启用状态：" prop="status">
            <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :disabled=disabled
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item class="form_item" label="绑定天数：" prop="bindTermVal">
            <el-input v-model="formData.bindTermVal" :disabled=disabled ></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="奖励触发事件：" prop="bindTermVal">
            <el-select v-model="formData.eventNo" placeholder="选择奖励触发事件" :disabled=disabled style="width: 250px;">
              <el-option
                v-for="(item,index) in eventList"
                :key="index"
                :label="item.eventName"
                :disabled="item.status === 0"
                :value="item.eventNo">
              </el-option>
            </el-select>

          </el-form-item>
          <el-form-item class="form_item" label="内容：" :rules="{ required: true, message: `请输入内容`, trigger: 'blur' }" style="width: 100%;">
            <custom-editor :option="option" v-model="formData.context" :disabled=disabled></custom-editor>
          </el-form-item>
        </div>
      </div>

    </el-form>
    <div class="form_btn" v-if="pageName!=='contentModuleDetail'">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm" v-if="!disabled">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

// 售卖价格验证方法
const checkIsPositive =(rule, value, callback)=> {
  if(!value){
    callback()
  } else {
    const reg = /^\d+(?=\.{0,1}\d+$|$)/
    if (reg.test(value)){
      callback()
    } else {
      callback(new Error('金额需大于等于0'))
    }
  }
}
export default {
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        pageTitle: "",
        disabled: false,
        formData: {
          taskNo:'',
          taskName: '',
          taskDesc:'',
          shareImage: '',
          invitationImage: '',
          bindTerm: 1,
          bindTermVal: '',
          context: '',
          status: 1,
          eventNo: ''
        },
        shareImageList:[],
        invitationImageList:[],
        formRules: {
          title: { required: true, message: '请输入内容标题', trigger: 'change' },
          content: { required: true, message: '请输入链接地址', trigger: 'change' },
        },
        option: {
          height: 500
        },
        eventList: []
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "代理任务", path: "/insurance/insuranceAgency/list" },
        {
          name: this.pageTitle
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 图片上传成功
    uploadSuccess(){

    },
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch(`insurance/saveAgentTask`,{
            ...this.formData
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
          })      
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    getDetail(taskNo){
      this.$store.dispatch('insurance/getAgentTaskDetail',{
        "taskNo": taskNo
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key];
        })
        this.shareImageList.push({url:this.formData.shareImage});
        this.invitationImageList.push({url:this.formData.invitationImage});
      })
    },

    getAllEventList(){
      this.$store.dispatch('insurance/allAgentEventList',{}).then(res => {
        this.eventList = res.data;
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 新建类型
    if (this.$route.name === "insuranceAgencyAdd") {
      this.pageTitle = "新建"
    } else if (this.$route.name === "insuranceAgencyEdit") {
      this.pageTitle = "编辑"
    } else if (this.$route.name === "insuranceAgencyShow") {
      this.pageTitle = "查看";
      this.disabled = true
    }
    this.getAllEventList();
    if (this.$route.query.taskNo) {
      this.getDetail(this.$route.query.taskNo);
    }
  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        // align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
          .form_item_explain{
            font-size: 12px;
            color: #999;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: flex-start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    // input[type="number"] {
    //   -moz-appearance: textfield;
    // }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .award_box{
    display: flex;
    flex-direction: column;
    .award_item{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      >.award_title{
        margin: 0 20px;
      }
      >div:nth-child(1){
        flex-shrink: 0;
      }
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
</style>
